// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "trix"
import "@rails/actiontext"
import "flowbite/dist/flowbite.turbo.js"
import "chartkick/chart.js"

// Non Stiumulus custom Javascript components
require("./src/uppy")

// prevents files in Trix Editor
// document.addEventListener("trix-file-accept", function(event) {
//   event.preventDefault();
// });

window.document.addEventListener("turbo:render", (_event) => {
  window.initFlowbite();
});

window.initAutoSuggest = function(...args) {
  const evt = new Event("google-maps-callback", {"bubbles":true, "cancelable":false});
  window.dispatchEvent(evt);
}

